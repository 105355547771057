import Main from './pages/main';
import Header from './pages/header';
import Footer from './pages/stack/one/footer';

function App() {
  return (
    <div>
      <Header/>
	  <Main/>
    </div>
  );
}

export default App;
