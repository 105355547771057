function Header(){
	return(
	<header className="header"><h1 className="logo"><a href="/" aria-current="page" title="GrapeRpa" className="nuxt-link-exact-active nuxt-link-active">
    GrapeRPA
  </a></h1>
  </header>
	)
	// <button className="menu-button"><span>Menu</span></button>
}

export default Header;
