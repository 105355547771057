export default function LinuxSvg() {
    return <svg version="1.1" role="presentation" width="16" height="16" viewBox="0 0 24 24" className="mo-icon MoIcon">
        <g stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0">
            <path
                d="M16.59,15.359a4.464,4.464,0,0,1,.979.432c.067.037.13.076.192.108.16-.015.419-.069.633-.114a5.774,5.774,0,0,1,1.171-.162,2.413,2.413,0,0,1,.4.035,7.268,7.268,0,0,0-.022-1.065A8.384,8.384,0,0,0,18.1,10.262c-.942-1.035-2.391-2.523-2.391-3.653V3.474a3.475,3.475,0,0,0-6.949,0V8.041c-.151.79-.546.866-1.167,1.732a10.318,10.318,0,0,0-1.45,2.674c-.29.87-.905,1.788-1.186,2.511a2.034,2.034,0,0,1,.23-.057,2.183,2.183,0,0,1,.34-.027,2.293,2.293,0,0,1,1.139.313A16.567,16.567,0,0,1,8.763,10.3a4.509,4.509,0,0,0,.7-2.918c.364.29.727,1.192,1.719,1.192S12.837,7.8,13.389,7.52a1.5,1.5,0,0,0,.382-.274l.095.022a46.862,46.862,0,0,1,2.3,4.915A11.1,11.1,0,0,1,16.59,15.359ZM10.94,5.274a2.247,2.247,0,0,0-.466.148l-.077.036a2.478,2.478,0,0,0-.376.216h0a1.325,1.325,0,0,1-.342-.826c-.049-.58.205-1.076.566-1.106s.693.416.742,1A1.571,1.571,0,0,1,10.94,5.274Zm2.4-.418a1.6,1.6,0,0,1-.279.8c-.057-.03-.12-.061-.185-.092l-.049-.024c-.078-.036-.162-.07-.252-.1l-.01,0a3.2,3.2,0,0,0-.678-.167,1.77,1.77,0,0,1-.038-.537c.055-.654.433-1.156.845-1.121S13.4,4.2,13.34,4.856Z"></path>
            <path
                d="M6.7,17.286c-.339-.514-.8-.979-1.268-.9s-.791.917-1.092,1.168-1.456.025-1.808.4-.025,1.719-.088,2.36-.4.979-.439,1.28.063.64.628.841,2.674.728,3.4.891,2.435,1.243,3.239-.062a2.193,2.193,0,0,0-.477-2.75A33.772,33.772,0,0,1,6.7,17.286Z"></path>
            <path
                d="M11.691,21.674a6.427,6.427,0,0,1-.666-.035,3.216,3.216,0,0,1,.02,1.059,13.928,13.928,0,0,1,2.583.092,4.6,4.6,0,0,1,.082-1.626A4.188,4.188,0,0,1,11.691,21.674Z"></path>
            <path
                d="M21.536,19.734a2.4,2.4,0,0,1-1.217-1.255c-.113-.5-.013-.967-.4-1.268s-1.659.273-2.409.18c-.526-.066-1.31-.889-1.845-.548-.318.2-.152,1.008-.14,1.435a22.877,22.877,0,0,1-.188,2.561c-.088.489-.766,2.033.552,2.912a2.014,2.014,0,0,0,2.461-.54,7.4,7.4,0,0,1,1.87-1.393,4.616,4.616,0,0,0,1.67-1.054C22.089,20.425,22.064,20.035,21.536,19.734Z"></path>
        </g>
    </svg>
}
